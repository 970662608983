.paginationBttns {
    width: 80%;
    /* height: 40px; */
    list-style: none;
    display: flex;
    justify-content: center;
}

.paginationBttns a {
    padding: 10px;
    margin: 8px;
    border-radius: 8px;
    border: 1px #4a74aa;
    color: #4a74aa;
    cursor: pointer;
}

.paginationBttns a:hover {
    color: white;
    background-color: #4a74aa;
}

.paginationActive a {
    color: white;
    background-color: #4a74aa;
}

a {
    text-decoration: none;
}

a :visited {
    color: black;
}