html,
body {
  height: 100%;
  margin: 0;
  text-decoration: none;
}

::-webkit-scrollbar {
width: 9px;
height: 9px;
}

::-webkit-scrollbar-thumb {
-webkit-border-radius: 4px;
border-radius: 4px;
background: rgb(189, 189, 189);
}

::-webkit-scrollbar-thumb:hover {
background: rgb(159, 159, 159);
}